@import url('https://fonts.googleapis.com/css2?family=Gochi+Hand&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Gochi+Hand&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

.app-layout {
  display: flex;
  height: 100vh;
  overflow: hidden;
}

.content,
.patient-info {
  overflow-y: auto;
  overflow-x: hidden;
}

.logout-btn {
  position: absolute;
  top: 10px;
  right: 20px;
  padding: 5px 15px;
  background-color: #ff4b4b;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.3s ease;
}

.logout-btn:hover {
  background-color: #e03e3e;
}
